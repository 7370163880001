<template>
  <div>
    <section-banner v-for="(section, index) in sections" :key="index" :model="section"></section-banner>
  </div>
</template>

<script>
import SectionBanner from "@/components/Frontend/SectionBanner";

export default {
  name: 'PropertyLaw',
  components: {
    SectionBanner
  },
  data() {
    return {
      sections: [
        {
          Name: "What we do",
          Title: "Property Law",
          Subtitle: "Buying, selling, or developing your property, and resolving disputes.",
          Description: "Decisions around property are often the most momentous of your life.",
          img: require("@/assets/img/Josh.jpg"),
        },
        {
          TextColumn: true,
          Decoration: require("@/assets/img/Commas.svg"),
          TextColumn1: `<p>You’ll get professional and expedient advice, from someone that understands the unique particulars of your life and your business. &nbsp;We can help you assess and minimise risk, with single-minded strategies honed to achieve one outcome: developing your personal and business interests. <br></p>
            <p>Whether you need to buy or sell a family home or develop large-scale real estate, we can apply our expertise and experience to:<br></p>
            <ul>
              <li>Property Registration and Transfers</li>
              <li>Consolidation of erven and sectional title property</li>
              <li>Registration of Sectional Titles Schemes</li>
              <li>Protection of Property Rights</li>
              <li>Assistance with setting up Body Corporate and Homeowner Associations as well as assisting with disputes relating thereto</li>
              <li>Property Development</li>
              <li>Drafting Agreements of sale and lease agreements</li>
              <li>Assisting with Subdivision of conventional and sectional property</li>
            </ul>`,
          TextColumn2: "<blockquote>That sale agreement is a cracker, thank you very much”<br><br>- Rowan Lewis, Quorum Property Development Ltd</blockquote>",
        },
        {
          Profiles: true,
          Name: "Talk to Us",
            Bios: [
              {
              Title: "Josh Schumacher",
              Subtitle: "Associate",
              Linkedin: "linkedin.com/in/joshua-schumacher",
              Email: "josh@NLAteam.com",
              Tel: "+27 11 704 1563",
              img: require("@/assets/img/Josh-bio.jpg"),
              left: true,
              last: true
            }
          ],
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>

</style>